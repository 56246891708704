import React, { useEffect, useState } from 'react';
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import './CreditCard.css'; // Create a CSS file for styling
import { Delete } from '@mui/icons-material';
import APIs from './APIs';
import { useNavigate } from 'react-router-dom';

function CreditCard(props) {

  const [cards,setCards]=useState([])
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
  });

  const navigate=useNavigate()

  const getUserData=async ()=>{
        const response= await APIs.getUserData()
    console.log(response)
    if(response.status!==401){

      if(response.data.status=="success"){
    setFormData({
        firstName:response.data.data.first_name,
        lastName:response.data.data.last_name,
        phoneNumber:response.data.data.mobile,
        email:response.data.data.email
      })
      }
  
    }
    else{

      
      navigate("/registration/login")
    }
  }
  const getCards=async()=>{

    const response= await APIs.getCards()

    if(response.data.status=="success"){
      console.log(response.data)
      setCards(response.data.data)
    }
  }
  useEffect(()=>{

    getCards()
    getUserData()
  },[])

  return (
    <div className='container'>
              <h2 className="text-right"  style={{
        marginBottom:50,
        textAlign:"right"
      }}>البطاقات</h2>

        <div className="row" dir='rtl'>

      {cards.map((card, index) => (
          <div key={index} dir='ltr' className="col-md-6 col-sm-12 col-lg-4 col-xl-3" style={{
            marginBottom:20
          }}>
         
          <Cards
            number={"************"+card.last4}
            expiry={card.exp_month+"/"+card.exp_year}
            cvc={"000"}
            name={formData.firstName+" "+formData.lastName}
            focused={"number"}
            preview={true}
            issuer={card.brand}
          />
          
            <div
            style={{
                maxWidth:200,
                marginLeft:"auto",
                marginRight:"auto"
            }}
            >
{
                   card.defaultcard?(
                 <div style={{
                width:150,
                backgroundColor:"#101924",
                marginLeft:"auto",
                marginRight:"auto",
                borderBottomRightRadius:10,
                borderBottomLeftRadius:10
            }}><p style={{
                textAlign:"center",
                color:"#fff"
            }}>
                البطاقة الافتراضية
                </p></div> 
            ):(
                <div className='row'>
  <div style={{
               width:50,
               backgroundColor:"#fff",
               marginLeft:"auto",
               marginRight:"auto",
               borderBottomRightRadius:10,
               borderBottomLeftRadius:10,
               boxShadow: '1px 2px 9px #00000011',
               cursor:"pointer"
           }}>
            <Delete style={{
                color:"red",
                marginLeft:"auto",
                marginRight:"auto",
                display:"block"
            }}
            onClick={async()=>{

              const response=await APIs.deleteCard(card.id)
              console.log(response)
              if(response.data.status=="success"){
                getCards()
              }
             }
            }
            />
            </div> 
            <div style={{
               maxWidth:150,
               backgroundColor:"#fff",
               marginLeft:"auto",
               marginRight:"auto",
               borderBottomRightRadius:10,
               borderBottomLeftRadius:10,
               boxShadow: '1px 2px 9px #00000011',
               cursor:"pointer"
           }}
           onClick={async()=>{

            const response=await APIs.makeCardDefault(card.id)
            console.log(response)
            if(response.data.status=="success"){
              getCards()
            }
           }
          }
           >
          
          <p
          style={{
            textAlign:"center",
            marginBottom:0
          }}
          
          >

            الاختيار كافتراضية
          </p>
            </div> 

                </div>
              
           )}
            </div>
         
          
           
        </div>
      ))}
    </div>  
    </div>
  
  );
}

export default CreditCard;
