import { useMemo,useState,useEffect } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Chip from '@mui/material/Chip';
import { useNavigate } from 'react-router-dom';
import APIs from './APIs';



const Orders = () => {
  //should be memoized or stable
  const [data,setOrders]=useState([])
  const navigate=useNavigate()

 const getOrders=async ()=>{

        const response = await APIs.getOrders()
        console.log(response.data)

        if(response.status!==401){

            if(response.data.status=="success"){
                var ordersres=response.data.data
             

                setOrders(ordersres)
            }
      
          }
          else{
      
            
            navigate("/registration/login")
          }
    }
  
  useEffect(()=>{

   
    getOrders()
},[])
function formatDateTime(inputDate) {
  // Parse the input date string
  const date = new Date(inputDate);

  // Get the components of the date
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  // Determine AM/PM
  const amOrPm = (date.getHours() < 12) ? 'AM' : 'PM';

  // Format the date string
  const formattedDate = `${day}-${month}-${year} ${hours}:${minutes} ${amOrPm}`;

  return formattedDate;
}
  const columns = useMemo(
    () => [
      {
        accessorKey: 'orderNum', //access nested data with dot notation
        header: 'رقم الطلب',
       size: 150,
       
          muiTableBodyCellProps: {
            align: 'right',
          },
       
      },
      {
        accessorKey: 'Pname',
        header: 'المنتج',
       size: 150,
      
       
          muiTableBodyCellProps: {
            align: 'right',
            
                  },
      },
      {
        accessorKey: 'dateadded', //normal accessorKey
        header: 'تاريخ الطلب',
        size: 200,
       
        muiTableBodyCellProps: {
          align: 'right',
        },
        Cell: ({ renderedCellValue, row }) =>
        (
         row.original.dateadded?formatDateTime(row.original.dateadded):""
        )
      },
      {
        accessorKey: 'auto_renew',
        header: 'تجديد تلقائي',
       size: 150,
       
          muiTableBodyCellProps: {
            align: 'right',
          },
          Cell: ({ renderedCellValue, row }) => (
          
            
            <FormControlLabel control={<Switch defaultChecked={row.original.auto_renew} onChange={async(e)=>{

              console.log(e.target.checked)

              await APIs.autoRenew(row.original.orderNum,e.target.checked?"1":"0")
            }}/>} />

            
                              ),
      },
      {
        accessorKey: 'status',
        header: 'الحالة',
       size: 150,
       
          muiTableBodyCellProps: {
            align: 'right',
          },
          Cell: ({ renderedCellValue, row }) => (
          
            
            row.original.status==0?<Chip label="جديد" size="small" color="primary" />:
            row.original.status==1?<Chip label="جار العمل" size="small" color="warning" />:
            row.original.status==2?<Chip label="جاهز" size="small" color="success" />:
            row.original.status==3?<Chip label="خطأ" size="small" color="error" />:""
            
            


  ),
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data,
  });

  return (
  
    <div className='container' dir='rtl'>
         <h2 className="text-right"  style={{
        marginBottom:50,
        textAlign:"right"
      }}>طلباتي</h2>

  <MaterialReactTable  table={table}/>

    </div>
  
  
  
  );
};

export default Orders;
