import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Login.css';
import { Link,useSearchParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading,setLoading]=useState(false)
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleLogin = () => {
    // Handle login logic here
    // Handle login logic here
    setLoading(true)


    fetch("https://paymentsapi.bombastic.ps/api/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json", 
      },
      body: JSON.stringify({
        email: email,
        password:password
      }),
    })
      .then((response) => {
        setLoading(false)

     
        return response.json(); // Parse the response as JSON
      })
      .then((data) => {
        // Handle the response data here
        setLoading(false)
        if(data.status=="success"){

        sessionStorage.setItem("token",data.token)
        const redirect=sessionStorage.getItem("redirect")||""
        sessionStorage.setItem("redirect","")
        navigate("/profile"+redirect||"")
        }
        else{

          toast.error(data.msg)

        }
      
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });   };


  useEffect(()=>{

    sessionStorage.setItem("token","")
    if(  searchParams.get("redirect")=="order")
    sessionStorage.setItem("redirect","/productData/"+searchParams.get("product"))

  },[])
  return (
    <div className="login-container">
        <ToastContainer/>
      <div className="login-box">
        <img src={require("./logo.jpg")} alt="الشعار" className="login-logo" />
        <form>
          <div className="form-group">
            <input
              type="email"
              className="form-control"
              placeholder="البريد الإلكتروني"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              className="form-control"
              placeholder="كلمة المرور"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button
            type="button"
            className="btn btn-primary btn-block"
            disabled={loading}
            onClick={handleLogin}
          >
            تسجيل الدخول
          </button>
          <div className="text-center mt-3">
            <Link to="/registration/forgotpassword">هل نسيت كلمة المرور؟</Link>
          </div>
          <div className="text-center mt-3">
            ليس لديك حساب؟ <Link to="/registration/signup">سجل الآن</Link>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
