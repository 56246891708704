import React, { useEffect, useState } from 'react';
import APIs from './APIs';
import { useNavigate } from 'react-router-dom';
import {  toast } from 'react-toastify';
function ArabicForm() {

  const navigate=useNavigate()
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
  });


  const getUserData=async ()=>{
        const response= await APIs.getUserData()
    console.log(response)
    if(response.status!==401){

      if(response.data.status=="success"){
    setFormData({
        firstName:response.data.data.first_name,
        lastName:response.data.data.last_name,
        phoneNumber:response.data.data.mobile,
        email:response.data.data.email
      })
      }

      else{

        toast.error(response.data.msg)
      }
  
    }
    else{

      
      navigate("/registration/login")
    }
  }

  useEffect(()=>{
getUserData()

  },[])

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSave = async() => {
    // You can add your save logic here, for example, sending the data to a server.
    const response= await APIs.updateUserData(JSON.stringify(formData))
    console.log(response)
    if(response.status!==401){

      if(response.data.status=="success"){
   
        toast.success(response.data.msg)
      }

      else{

        toast.error(response.data.msg)
      }
  
    }
    else{

      
      navigate("/registration/login")
    }

  };

  return (
    <div className="container" dir='rtl'>
      <h2 className="text-right">نموذج بيانات</h2>
      <div
      className='incontainer'
      style={{
        backgroundColor:"#fff",
        padding:20,
        borderRadius:5,
        boxShadow: '1px 2px 9px #00000011',
        marginTop:20,
        marginLeft:"auto",
        marginRight:"auto"

      }}
      >
           <form>
        <div className="form-group">
          <label htmlFor="firstName">الاسم الأول</label>
          <input
            type="text"
            className="form-control"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
            dir="rtl"
          />
        </div>

        <div className="form-group">
          <label htmlFor="lastName">الاسم الأخير</label>
          <input
            type="text"
            className="form-control"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
            dir="rtl"
          />
        </div>

        <div className="form-group">
          <label htmlFor="phoneNumber">رقم الهاتف</label>
          <input
            type="tel"
            className="form-control"
            id="phoneNumber"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleInputChange}
            dir="rtl"
          />
        </div>

        <div className="form-group">
          <label htmlFor="email">البريد الإلكتروني</label>
          <input
          disabled
            type="email"
            className="form-control"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            dir="rtl"
          />
        </div>

        <button type="button" className="btn btn-primary" onClick={handleSave}>
          حفظ
        </button>
      </form>
      </div>
   
    </div>
  );
}

export default ArabicForm;
