const url = "https://paymentsapi.bombastic.ps/api/";
//const url = "http://10.0.0.12:4000/api/";

export default {
    getUserData: async () => {
        try {
          const response = await fetch(url + "getUserData", {
            method: "get",
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + sessionStorage.getItem("token"),
            },
          });
    
          const data = await response.json();
    
          return {
            status: response.status,
            data: data,
          };
        } catch (error) {
          console.error(error);
          return {
            status: -1, // You can set a custom status code for errors
            data: null,
          };
        }
      },
      updateUserData: async (userData) => {
        try {
          const response = await fetch(url + "updateUserData", {
            method: "post",
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + sessionStorage.getItem("token"),
            },
            body:userData
          });
    
          const data = await response.json();
    
          return {
            status: response.status,
            data: data,
          };
        } catch (error) {
          console.error(error);
          return {
            status: -1, // You can set a custom status code for errors
            data: null,
          };
        }
      },
      getProducts: async () => {
        try {
          const response = await fetch(url + "getProducts", {
            method: "get",
            headers: {
              "Content-Type": "application/json",
            },
          });
    
          const data = await response.json();
    
          return {
            status: response.status,
            data: data,
          };
        } catch (error) {
          console.error(error);
          return {
            status: -1, // You can set a custom status code for errors
            data: null,
          };
        }
      },
      getProduct: async (id) => {
        try {
          const response = await fetch(url + "getProduct/"+id, {
            method: "get",
            headers: {
              "Content-Type": "application/json",
            },
          });
    
          const data = await response.json();
    
          return {
            status: response.status,
            data: data,
          };
        } catch (error) {
          console.error(error);
          return {
            status: -1, // You can set a custom status code for errors
            data: null,
          };
        }
      },
      newOrder: async (order) => {
        try {
          const response = await fetch(url + "newOrder", {
            method: "post",
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + sessionStorage.getItem("token"),
            },
            body:order
          });
    
          const data = await response.json();
    
          return {
            status: response.status,
            data: data,
          };
        } catch (error) {
          console.error(error);
          return {
            status: -1, // You can set a custom status code for errors
            data: null,
          };
        }
      },
      getTransactions: async () => {
        try {
          const response = await fetch(url + "getTransactions", {
            method: "get",
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + sessionStorage.getItem("token"),
            },
          });
    
          const data = await response.json();
    
          console.log(data)
          return {
            status: response.status,
            data: data,
          };
        } catch (error) {
          console.error(error);
          return {
            status: -1, // You can set a custom status code for errors
            data: null,
          };
        }
      },
      getOrders: async () => {
        try {
          const response = await fetch(url + "getOrders", {
            method: "get",
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + sessionStorage.getItem("token"),
            },
          });
    
          const data = await response.json();
    
          console.log(data)
          return {
            status: response.status,
            data: data,
          };
        } catch (error) {
          console.error(error);
          return {
            status: -1, // You can set a custom status code for errors
            data: null,
          };
        }
      },
      payWithDefaultCard: async (reference) => {
        try {
          const response = await fetch(url + "payWithDefaultCard", {
            method: "post",
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + sessionStorage.getItem("token"),
            },
            body:reference
          });
    
          const data = await response.json();
    
          return {
            status: response.status,
            data: data,
          };
        } catch (error) {
          console.error(error);
          return {
            status: -1, // You can set a custom status code for errors
            data: null,
          };
        }
      },
      autoRenew: async (id,value) => {
        try {
          const response = await fetch(url + "autoRenew?id="+id+"&value="+value, {
            method: "get",
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + sessionStorage.getItem("token"),
            },
          });
    
          const data = await response.json();
    
          console.log(data)
          return {
            status: response.status,
            data: data,
          };
        } catch (error) {
          console.error(error);
          return {
            status: -1, // You can set a custom status code for errors
            data: null,
          };
        }
      },
      getCards: async () => {
        try {
          const response = await fetch(url + "getCards", {
            method: "get",
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + sessionStorage.getItem("token"),
            },
          });
    
          const data = await response.json();
    
          console.log(data)
          return {
            status: response.status,
            data: data,
          };
        } catch (error) {
          console.error(error);
          return {
            status: -1, // You can set a custom status code for errors
            data: null,
          };
        }
      },
      makeCardDefault: async (id) => {
        try {
          const response = await fetch(url + "makeCardDefault?id="+id, {
            method: "get",
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + sessionStorage.getItem("token"),
            },
          });
    
          const data = await response.json();
    
          console.log(data)
          return {
            status: response.status,
            data: data,
          };
        } catch (error) {
          console.error(error);
          return {
            status: -1, // You can set a custom status code for errors
            data: null,
          };
        }
      },
      deleteCard: async (id) => {
        try {
          const response = await fetch(url + "deleteCard?id="+id, {
            method: "get",
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + sessionStorage.getItem("token"),
            },
          });
    
          const data = await response.json();
    
          console.log(data)
          return {
            status: response.status,
            data: data,
          };
        } catch (error) {
          console.error(error);
          return {
            status: -1, // You can set a custom status code for errors
            data: null,
          };
        }
      },
};
