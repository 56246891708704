import React, { useState } from "react";
import "./FilePick.css";
import BeatLoader  from "react-spinners/BeatLoader";

const Filepick = ({handleFieldChange,fieldname}) => {
  const [selectedfile, SetSelectedFile] = useState([]);
  const [Files, SetFiles] = useState([]);
  const [Loading, SetLoading] = useState(false);

  const filesizes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };
  function generateRandomId(length = 8) {
    const characters = '0123456789';
    let randomId = '';
  
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomId += characters.charAt(randomIndex);
    }
  
    return randomId;
  }
  
  const InputChange = async (e) => {
    // --For Multiple File Input

    SetLoading(true)
    if (e.target.files[0].type.includes("image")) {
      let images = [];
      for (let i = 0; i < e.target.files.length; i++) {
        images.push(e.target.files[i]);
        let reader = new FileReader();
        let file = e.target.files[i];

        reader.onloadend = async () => {
          const formData = new FormData();
          formData.append("file", file);

          try {
            const response = await fetch(
              "https://paymentsapi.bombastic.ps/api/uploadandgetlink",
              {
                method: "POST",
                body: formData,
              }
            );
            SetLoading(false)

            if (response.ok) {
              const data = await response.json();
              const fileURL = data.fileUrl;
              handleFieldChange(fileURL,fieldname)
              SetSelectedFile((preValue) => {
                return [
                  ...preValue,
                  {
                    id: generateRandomId(),
                    filename: e.target.files[i].name,
                    filetype: e.target.files[i].type,
                    fileimage: reader.result,
                    datetime:
                      e.target.files[i].lastModifiedDate.toLocaleString(
                        "en-IN"
                      ),
                    filesize: filesizes(e.target.files[i].size),
                    url: fileURL,
                  },
                ];
              });
              // Update the form data with the new profile picture URL
            } else {
              console.error("Failed to upload the profile picture.");
            }
          } catch (error) {
            SetLoading(false)

            console.error("Error uploading the profile picture:", error);
          }
        };
        if (e.target.files[i]) {
          reader.readAsDataURL(file);
        }
      }
    }
  };

  const DeleteSelectFile = (id) => {
    if (window.confirm("Are you sure you want to delete this Image?")) {
      const result = selectedfile.filter((data) => data.id !== id);
      SetSelectedFile(result);
    } else {
      // alert('No');
    }
  };

  const FileUploadSubmit = async (e) => {
    console.log(selectedfile);
  };

  const DeleteFile = async (id) => {
    if (window.confirm("Are you sure you want to delete this Image?")) {
      const result = Files.filter((data) => data.id !== id);
      SetFiles(result);
    } else {
      // alert('No');
    }
  };

  return (
    <div className="fileupload-view" dir="rtl">
      <div className="row justify-content-center m-0">
        <div className="col-md-6">
          <div className="card1 mt-5">
            <div className="card-body1">
              <div className="kb-data-box">
                <div className="kb-modal-data-title">
                  <div className="kb-data-title">
                    <h6>شعار التطبيق</h6>
                  </div>
                </div>
                <form>
                  {selectedfile.length == 0 && !Loading&& (
                    
                    <div className="kb-file-upload">
                      <div className="file-upload-box">
                        <input
                          type="file"
                          id="fileupload"
                          className="file-upload-input"
                          onChange={InputChange}
                          accept="image/*"
                        />
                        <span>
                          اسحب الملف هنا أو {" "}
                          <span className="file-link">اختر الملف</span>
                        </span>
                      </div>
                    </div>
                  )}
{
    Loading&&(
       <div
style={{
   display:"flex",
   justifyContent:"center",
   padding:30
}}
>
<BeatLoader 
        loading={Loading}
        size={20}
        aria-label="Loading Spinner"
        data-testid="loader"
        color="#176CC7"
      />
</div> 
    )
}


                  <div className="kb-attach-box mb-3">
                    {selectedfile.map((data, index) => {
                      const {
                        id,
                        filename,
                        filetype,
                        fileimage,
                        datetime,
                        filesize,
                      } = data;
                      return (
                        <div className="file-atc-box" key={id}>
                          {filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ? (
                            <div className="file-image">
                              {" "}
                              <img src={fileimage} alt="" />
                            </div>
                          ) : (
                            <div className="file-image">
                              <i className="far fa-file-alt"></i>
                            </div>
                          )}
                          <div className="file-detail">
                            <h6>{filename}</h6>
                            <p></p>
                            <p>
                              <span>حجم الملف : {filesize}</span>
                             
                            </p>
                            <div className="file-actions">
                              <button
                                type="button"
                                className="file-action-btn"
                                onClick={() => DeleteSelectFile(id)}
                              >
                                حذف
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                 
                </form>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filepick;
