import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Login.css';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import { useNavigate } from "react-router-dom";

function NewPassword() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading,setLoading]=useState(false)
  const navigate = useNavigate();

  const handleChangePassword = () => {
    // Handle password change logic here
    if(newPassword===confirmPassword){
        setLoading(true)

        // Handle signup logic here
    
        fetch("https://paymentsapi.bombastic.ps/api/newPassword", {
          method: "POST",
          headers: {
            "Content-Type": "application/json", 
            "Authorization":"Bearer "+sessionStorage.getItem("token")
          },
          body: JSON.stringify({
            password:newPassword
          }),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json(); // Parse the response as JSON
          })
          .then((data) => {
            // Handle the response data here
            setLoading(false)
            if(data.status=="success"){
            toast.success(data.msg)
    
            navigate("/registration/login")
            }
            else{
    
              toast.error(data.msg)
    
            }
          
          })
          .catch((error) => {
            console.error("There was a problem with the fetch operation:", error);
          });  
        
    }
    else{
        toast.error("كلمة المرور لا تطابق التأكيد")

    }
  
    };

  return (
    <div className="login-container">
        <ToastContainer/>
      <div className="login-box">
      <img src={require("./logo.jpg")} alt="الشعار" className="login-logo" />
        <h2 className="login-header">إنشاء كلمة مرور جديدة</h2>
        <form>
          <div className="form-group">
            <input
              type="password"
              className="form-control"
              placeholder="كلمة المرور الجديدة"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              className="form-control"
              placeholder="تأكيد كلمة المرور"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          <button
            type="button"
            className="btn btn-primary btn-block"
            disabled={loading}
            onClick={handleChangePassword}
          >
            تغيير كلمة المرور
          </button>
          <div className="text-center mt-3">
            لديك حساب بالفعل؟ <Link to="/registration/login">تسجيل الدخول</Link>
          </div>
        </form>
      </div>
    </div>
  );
}

export default NewPassword;
