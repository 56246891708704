import React, { useEffect, useState } from 'react';
import './Pricing.css';
import { Modal, Box, Typography, TextField, Switch, Button, Input } from '@mui/material';
import FilePick from './Filepicker';
import { useNavigate } from 'react-router-dom';
import APIs from './APIs';
const Pricing = () => {
  
    const [products,setProducts]=useState([])
    const navigate=useNavigate()
 

    useEffect(()=>{

        const getProducts=async ()=>{

            const response = await APIs.getProducts()
            if(response.status!==401){

                if(response.data.status=="success"){
                    var productsRes=response.data.data
                    productsRes.forEach(p => {
                        p.linkColor="var(--color04)"
                        p.bgColor="var(--color07)"
                        p.imageUrl="https://raw.githubusercontent.com/mohammadjarabah/codepen-assets/main/pens/xxXgjem/images/img3.jpg"
                        p.features=JSON.parse(p.features)
                        p.requireddata=JSON.parse(p.requireddata)
                    });
                  

                    setProducts(productsRes)
                }
          
              }
              else{
          
                
                navigate("/registration/login")
              }
        }
        getProducts()
    },[])

    return (
      <div className="container">
         <h2
        className="text-right"
        style={{
          marginBottom: 50,
          textAlign: "right",
        }}
      >
        المنتجات
      </h2>
        <div className="row">
          {products.map((item, index) => (
            <div className="col-md-6 col-lg-4 " key={index}>
              <div className="card">
                <div
                  className="card__info"
                  style={{ backgroundImage: `url(${item.imageUrl})`, backgroundColor: item.bgColor }}
                >
                  <h2 className="card__name">{item.Pname}</h2>
                  <p className="card__price" style={{ color: item.bgColor, }}>
                  {item.price +" "+ item.currency}<span className="card__priceSpan">/{item.period} يوم</span>
                  </p>
                </div>
                <div className="card__content" style={{ borderColor: item.bgColor }}>
                  <div className="card__rows">
                    {item.features.map((feature, i) => (
                      <p className="card__row" key={i}>
                        {feature}
                      </p>
                    ))}
                  </div>
                  <a onClick={() => navigate("/profile/productData/"+item.id)} className="card__link" style={{ backgroundColor: item.linkColor, cursor: "pointer" }}>
                الطلب الان
              </a>
                </div>
              </div>
            </div>
          ))}
        </div>
        
    </div>
  );
};

export default Pricing;