import logo from './logo.svg';
import './App.css';
import SideNav from './components/Sidebar';
import Admin from './components/Sidebar';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Blank from './components/Blank';
import { ProSidebarProvider,useProSidebar } from 'react-pro-sidebar';
import 'bootstrap/dist/css/bootstrap.min.css';
import Signup from "./components/registration/Signup"
import RegRouter from './components/registration/RegRouter';
import Adminlogin from './components/adminReg/RegRouter';
import { ToastContainer } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
function App() {
 
  return (
    <ProSidebarProvider>

<ToastContainer/>
    
    <Routes>
    <Route path="/*" element={<RegRouter/> } />
    <Route path="/registration/*" element={<RegRouter/> } />
    <Route path="/profile/*" element={<SideNav/> } />
    <Route path="/admin/*" element={<Admin/> } />
    <Route path="/adminlogin/*" element={<Adminlogin/> } />



        </Routes>
</ProSidebarProvider>
  );
}

export default App;
