import { useEffect, useState } from 'react';
import { Link, useLocation,useNavigate } from 'react-router-dom';
import { Sidebar, Menu, MenuItem,useProSidebar, SubMenu } from "react-pro-sidebar";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import BubbleChartRoundedIcon from "@mui/icons-material/BubbleChartRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import { Routes, Route  } from "react-router-dom";
import MyData from '../MyData';
import CreditCard from '../CreditCard';
import Orders from '../Orders';
import Payments from '../Payments';
import PricingPage from '../Pricing';
import RequiredData from '../RequiredData';
import Navbar from '../Navbar';
const Sidebar1 = () => {
  const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } =
  useProSidebar();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(()=>{

    console.log(toggled)
  },[toggled])

  useEffect(() => {
    // execute on location change
    console.log('Location changed!', location.pathname);

    if(!sessionStorage.getItem("token")){

      navigate("/registration/login")
    }
}, [location]);
    return(
<>
<Navbar/>
{
        broken&&!toggled&&(

          <div
          style={{
            width:"60px",
            display:"flex",
            flexDirection:"row-reverse",
            padding:10,
            backgroundColor:"#fff",
            zIndex:500,
            position:"relative",
            marginLeft:"auto"
            

          }}
          >
           <MenuRoundedIcon
         
         fontSize='large'
                onClick={() => {
                  toggleSidebar()
                }}
               /> 
                  
          </div>
        
        )
      }
<div style={{ display: "flex", height: "100%",minHeight:"100vh" , flexDirection: "row-reverse"}}>
      <Sidebar className="app" rtl={true} backgroundColor='#101924' breakPoint='sm'>
        <Menu
         menuItemStyles={{
          
          button: ({ level, active, disabled }) => {
            // only apply styles on first level elements of the tree
           
              return {
                color: "#FFFFF2",
                backgroundColor: active ? '#182536' : "#101924",
                '&:hover': {
                  backgroundColor: "#182536",
                  color: "#fff",
                },
              };
          },
        }}
        >
        <MenuItem
           
            className="menu1"
            icon={
              <MenuRoundedIcon
               
              />
            }
            onClick={() => {
              collapseSidebar()
            }}
          >         
          </MenuItem>
          <MenuItem active={location.pathname=="/profile/MyData"} icon={<GridViewRoundedIcon />} component={<Link to="MyData" />}> معلوماتي </MenuItem>
          <MenuItem active={location.pathname=="/profile/Cards"} component={<Link to="Cards" />} icon={<BubbleChartRoundedIcon />}>البطاقات</MenuItem>
          <SubMenu icon={<GridViewRoundedIcon />} label="طلباتي">
          <MenuItem active={location.pathname=="/profile/newOrder"} component={<Link to="newOrder" />} icon={<BubbleChartRoundedIcon />}>اضافة طلب جديد</MenuItem>
          <MenuItem active={location.pathname=="/profile/Orders"} component={<Link to="Orders" />} icon={<BubbleChartRoundedIcon />}>طلباتي</MenuItem>

          </SubMenu>
          <MenuItem active={location.pathname=="/profile/Payments"} component={<Link to="Payments" />} icon={<BubbleChartRoundedIcon />}>الدفعات</MenuItem>
  
         
          <MenuItem icon={<LogoutRoundedIcon />} component={<Link to="/" />}> تسجيل الخروج </MenuItem>

          <img src={require("../verifiedbyvisanadmastercard.png")} style={{
            width:"100%",
            padding:"20px"
          }}></img>
        </Menu>
      </Sidebar>

            
   
  
  <div
  style={{
    width:"100%",
    padding:20,
    paddingTop:50
  }}
  >
     <Routes>
          <Route path="/" element={<MyData/>} />
          <Route path="/MyData" element={<MyData/>} />
          <Route path="/Orders" element={<Orders/>} />
          <Route path="/newOrder" element={<PricingPage/>} />
          <Route path="/Payments" element={<Payments/>} />
          <Route path="/productData/:id" element={<RequiredData/>} />
          <Route path="/Cards" element={<><CreditCard cards = {[
    {
      number:"************1234",
      expiry:"12/21",
      name:"Wissam Ardah",
      issuer:"visa",
      default:true
  },
  {
    number:"************1234",
    expiry:"12/21",
      name:"Wissam Ardah",
      issuer:"mastercard"
  },
  {
    number:"************1234",
    expiry:"12/21",
      name:"Wissam Ardah",
      issuer:"visa"
  },
  {
    number:"************1234",
    expiry:"12/21",
      name:"Wissam Ardah",
      issuer:"maestro"
  },
  {
    number:"************1234",
    expiry:"12/21",
    name:"Wissam Ardah",
    issuer:"visa",
},
{
  number:"************1234",
  expiry:"12/21",
    name:"Wissam Ardah",
    issuer:"mastercard"
},
{
  number:"************1234",
  expiry:"12/21",
    name:"Wissam Ardah",
    issuer:"visa"
},
{
  number:"************1234",
  expiry:"12/21",
    name:"Wissam Ardah",
    issuer:"maestro"
}
]}/></>} />


        </Routes>
  </div>
     
            </div>
</>
    );
};

export default Sidebar1;