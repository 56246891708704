import React, { useState,useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Login.css';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import { useNavigate } from "react-router-dom";
  import { useSearchParams } from 'react-router-dom';

function Signup() {
  const [fname, setfName] = useState('');
  const [lname, setlName] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const [loading,setLoading]=useState(false)
  const navigate = useNavigate();

  const handleSignup = () => {
    setLoading(true)

    // Handle signup logic here

    fetch("https://paymentsapi.bombastic.ps/api/signup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json", 
      },
      body: JSON.stringify({
        email: email,
        password:password,
        fname:fname,
        lname:lname,
        mobile:mobile
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json(); // Parse the response as JSON
      })
      .then((data) => {
        // Handle the response data here
        console.log(data)
        setLoading(false)

        if(data.status=="success"){
           toast.success(data.msg)
          navigate("/registration/otpcheck?from=signup&email="+email)
       
        }
        else{

          toast.error(data.msg)

        }
      
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };
  useEffect(()=>{

    sessionStorage.setItem("token","")
    if(  searchParams.get("redirect")=="order")
    sessionStorage.setItem("redirect","/productData/"+searchParams.get("product"))

  },[])
  return (
    <div className="login-container">
      <ToastContainer/>
      <div className="login-box">
      <img src={require("./logo.jpg")} alt="الشعار" className="login-logo" />
        <h2 className="login-header">تسجيل حساب جديد</h2>
        <form>
        <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="الاسم الاول"
              value={fname}
              onChange={(e) => setfName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="الاسم الاخير"
              value={lname}
              onChange={(e) => setlName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <input
              type="email"
              className="form-control"
              placeholder="البريد الإلكتروني"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group">
            <input
              type="tel"
              className="form-control"
              placeholder="رقم الهاتف"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              className="form-control"
              placeholder="كلمة المرور"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button
            type="button"
            className="btn btn-primary btn-block"
            disabled={loading}
            onClick={handleSignup}
          >
            سجل الآن
          </button>
          <div className="text-center mt-3">
            لديك حساب بالفعل؟ <Link to="/registration/login">تسجيل الدخول</Link>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Signup;
