import { useMemo,useEffect,useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Chip from "@mui/material/Chip";
import { MenuItem, Box, Button } from "@mui/material";
import Menu from "@mui/material/Menu";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import APIs from "./APIs";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";


const Payments = () => {
  const [data,setTransactions]=useState([])
  const navigate=useNavigate()

 const getTransactions=async ()=>{

        const response = await APIs.getTransactions()
        console.log(response.data)

        if(response.status!==401){

            if(response.data.status=="success"){
                var transactionsres=response.data.data
             

                setTransactions(transactionsres)
            }
      
          }
          else{
      
            
            navigate("/registration/login")
          }
    }
  
  useEffect(()=>{

   
    getTransactions()
},[])

function formatDateTime(inputDate) {
  // Parse the input date string
  const date = new Date(inputDate);

  // Get the components of the date
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  // Determine AM/PM
  const amOrPm = (date.getHours() < 12) ? 'AM' : 'PM';

  // Format the date string
  const formattedDate = `${day}-${month}-${year} ${hours}:${minutes} ${amOrPm}`;

  return formattedDate;
}
  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "reference", //access nested data with dot notation
        header: "رقم الدفعة",
        size: 150,

        muiTableBodyCellProps: {
          align: "right",
        },
      },
      {
        accessorKey: "orderid",
        header: "رقم الطلب",
        size: 150,

        muiTableBodyCellProps: {
          align: "right",
        },
      },
      {
        accessorKey: "amount", //normal accessorKey
        header: "القيمة",
        size: 200,

        muiTableBodyCellProps: {
          align: "right",
        },
      },
      {
        accessorKey: "currency",
        header: "العملة",
        size: 150,

        muiTableBodyCellProps: {
          align: "right",
        },
      },
      {
        accessorKey: "dateadded",
        header: "تاريخ الاضافة",
        size: 150,

        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ renderedCellValue, row }) =>
       (
        formatDateTime(row.original.dateadded)
       )
      },
      {
        accessorKey: "paidAt", //normal accessorKey
        header: "تاريخ الدفع",
        size: 200,

        muiTableBodyCellProps: {
          align: "right",
        },
        
        Cell: ({ renderedCellValue, row }) =>
       (
        row.original.paidAt?formatDateTime(row.original.paidAt):""
       )
      },
      {
        accessorKey: "notes", //normal accessorKey
        header: "تفاصيل",
        size: 200,

        muiTableBodyCellProps: {
          align: "right",
        },
      },
      {
        accessorKey: "status",
        header: "الحالة",
        size: 150,

        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ renderedCellValue, row }) =>
          row.original.status == "success" ? (
            <Chip label="مدفوعة" size="small" color="success" />
          ) : (
            <Chip label="لم يتم الدفع" size="small" color="error" />
          ),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowActions: true,
    displayColumnDefOptions: {
      "mrt-row-actions": {
        header: "الدفع", //change header text
      },
    },
    renderRowActions: ({ row }) =>
      row.original.status !== "success" && (
        <PopupState variant="popover" popupId="demo-popup-menu">
          {(popupState) => (
            <>
              <Button variant="contained" {...bindTrigger(popupState)}>
                ادفع
              </Button>
              <Menu {...bindMenu(popupState)}>
                <MenuItem onClick={async() => {

const response = await APIs.payWithDefaultCard(JSON.stringify({
  reference:row.original.reference

}))
console.log(response)
  if(response.status!==401){

      if(response.data.status=="success"){
          toast.success(response.data.msg)

          window.location.reload(true);
        }
      else
      toast.error(response.data.msg)
      window.location.reload(true);

    }
    else{

      
      navigate("/registration/login")
    }



                }}>
                  باستخدام البطاقة الافتراضية
                </MenuItem>
                <MenuItem onClick={()=>{
                  console.log(row.original.authorization_url)
                  window.open(row.original.authorization_url, '_self');

                }}>
                  باستخدام بطاقة جديدة
                </MenuItem>
              </Menu>
            </>
          )}
        </PopupState>
      ),
    positionActionsColumn: "last",
  });


  
  return (
    <div className="container" dir="rtl">
      <h2
        className="text-right"
        style={{
          marginBottom: 50,
          textAlign: "right",
        }}
      >
        الدفعات
      </h2>

      <MaterialReactTable table={table} />
    </div>
  );
};

export default Payments;
