import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Signup from './Signup';
import Login from './Login';
import OtpCheck from './OtpCheck';
import NewPassword from './NewPassword';
import Forgotpassword from './Forgotpassword';
const RegRouter = () => {
    return (

        <Routes>

<Route path="/" element={<Login/> } />
<Route path="login" element={<Login />} />
        <Route path="signup" element={<Signup />} />
        <Route path="forgotpassword" element={<Forgotpassword />} />
        <Route path="otpcheck" element={<OtpCheck />} />
        <Route path="newpassword" element={<NewPassword />} />
        </Routes>
    );
};

export default RegRouter;