import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Login.css';
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

function OtpCheck() {
  const [otp, setOtp] = useState('');
  const [loading,setLoading]=useState(false)
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(document.location.search)

  const handleVerify = () => {
    setLoading(true)

    // Handle signup logic here

    fetch("https://paymentsapi.bombastic.ps/api/checkotp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json", 
      },
      body: JSON.stringify({
        email: searchParams.get('email'),
        otp:otp
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json(); // Parse the response as JSON
      })
      .then((data) => {
        // Handle the response data here
        setLoading(false)
        if(data.status=="success"){
        toast.success(data.msg)

        sessionStorage.setItem("token",data.token)
        if(searchParams.get('from')=="signup")
        {
          const redirect=sessionStorage.getItem("redirect")||""
          sessionStorage.setItem("redirect","")
          navigate("/profile"+redirect.startsWith("/")?redirect:"")
        }
    else
    navigate("/registration/newpassword")
        }
        else{

          toast.error(data.msg)

        }
      
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  return (
    <div className="login-container">
        <ToastContainer/>
      <div className="login-box">
      <img src={require("./logo.jpg")} alt="الشعار" className="login-logo" />
        <h2 className="login-header">تحقق من الرمز</h2>
        <form>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="الرمز المؤقت"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
          </div>
          <button
            type="button"
            className="btn btn-primary btn-block"
            disabled={loading}
            onClick={handleVerify}
          >
            تحقق
          </button>
          <div className="text-center mt-3">
            لديك حساب بالفعل؟ <Link to="/registration/login">تسجيل الدخول</Link>
          </div>
        </form>
      </div>
    </div>
  );
}

export default OtpCheck;
