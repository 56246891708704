import React,{useState,useEffect} from "react";
import { Modal, Box, Typography, TextField, Switch, Button, Input } from '@mui/material';
import FilePick from './Filepicker';
import { useParams } from "react-router-dom";
import APIs from './APIs';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";


const RequiredData = () => {
const [selectedProduct,setSelectedProduct]=useState(null)
const [formData, setFormData] = useState({});
const { id } = useParams();
const navigate=useNavigate()


  const handleSave = async() => {
    console.log(formData)

    const response = await APIs.newOrder(JSON.stringify({
        product:id,
        auto_renew:1,
        requireddata:JSON.stringify(formData)

    }))
    console.log(response)
        if(response.status!==401){

            if(response.data.status=="success"){
                toast.success("تم ارسال الطلب بنجاح")
                window.open(response.data.url,'_self')
                navigate("/profile/Orders")
            }
            else
            toast.error(response.data.msg)

      
          }
          else{

            
            navigate("/registration/login")
          }
  };

  const handleFieldChange = (value, field) => {
    setFormData({ ...formData, [field]: value });
  };

  useEffect(()=>{
    const getProduct=async ()=>{

        const response = await APIs.getProduct(id)
        if(response.status!==401){

            if(response.data.status=="success"){
                var productsRes=response.data.data
                productsRes.forEach(p => {
                    p.linkColor="var(--color04)"
                    p.bgColor="var(--color07)"
                    p.imageUrl="https://raw.githubusercontent.com/mohammadjarabah/codepen-assets/main/pens/xxXgjem/images/img3.jpg"
                    p.features=JSON.parse(p.features)
                    p.requireddata=JSON.parse(p.requireddata)
                });
              


                setSelectedProduct(productsRes[0]||{})

            }
      
          }
          else{
      
            
            navigate("/registration/login")
          }
    }
    getProduct()  
},[])
    return (

        <>
        {selectedProduct && (
          <Box sx={{  margin: 'auto', padding: 2 }}>
            <Typography variant="h5" sx={{ textAlign: 'center' }}>
              البيانات المطلوبة لـ {selectedProduct.Pname}
            </Typography>
            <form>
              {selectedProduct.requireddata.map((field, index) => (
                <div key={index}>
                  {field.type === 'text' && (
                    <TextField
                      label={field.name}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={formData[field.name] || ''}
                      onChange={(e) => handleFieldChange(e.target.value, field.name)}
                    />
                  )}
                  {field.type === 'file' && (
                    <FilePick handleFieldChange={handleFieldChange} fieldname={field.name}/>
                  )}
                 
                  {/* Add more cases for other input types */}
                </div>
              ))}
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSave}
                sx={{ marginTop: 2 }}
              >
                إرسال
              </Button>
            </form>
          </Box>
        )}
        </>
    );
};

export default RequiredData;